<template>
  <v-container fluid>
    <v-data-table :headers="headers" :items="roles">
      <template v-slot:[`item.permissionCount`]="{ item }">
        <b>{{ item.permissionCount }}</b> {{ $t("roles.permissionCounts") }}
      </template>

      <template v-slot:[`item.isDefault`]="{ item }">
        <v-icon color="success" v-if="item.isDefault">mdi-check</v-icon>
        <v-icon color="error" v-else>mdi-close-octagon-outline</v-icon>
      </template>

      <template v-slot:[`item.isSuper`]="{ item }">
        <v-icon color="success" v-if="item.isSuper">mdi-check</v-icon>
        <v-icon color="error" v-else>mdi-close-octagon-outline</v-icon>
      </template>

      <template v-slot:[`item.action`]="{ item }">
        <v-btn router :to="`/roles/edit/${item.userRoleId}`" icon>
          <v-icon
            dark
            color="primary"
            v-if="!item.isDefault && permitted('Roles.Update')"
          >
            edit
          </v-icon>
        </v-btn>
      </template>
    </v-data-table>
  </v-container>
</template>

<script>
import { mapActions, mapState } from "vuex";
import { Roles } from "@/_helpers/Role";

export default {
  name: "RoleList",

  methods: {
    ...mapActions("roles", ["getRolesByCompany"]),
  },

  computed: {
    ...mapState("roles", ["roles"]),
    Roles() {
      return Roles;
    },
  },

  data() {
    return {
      headers: [
        { text: this.$t("roles.fields.name"), value: "name" },
        {
          text: this.$t("roles.fields.permissionCount"),
          value: "permissionCount",
        },

        {
          text: this.$t("roles.fields.isDefault"),
          value: "isDefault",
        },

        {
          text: this.$t("roles.fields.isSuper"),
          value: "isSuper",
        },

        {
          text: "",
          value: "action",
        },
      ],
    };
  },

  async created() {
    await this.getRolesByCompany();
  },
};
</script>